<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-28 15:28:24
-->
<template>
  <div class="container-warp">
    <!-- 头部筛选--开始 -->
      <el-card class="card-pd" shadow='never'>
        <div slot="header">
          <el-form  class="form-item-no-mb" :model="searchForm"  ref="searchForm" inline>
            <el-form-item label="月份">
              <el-date-picker
                v-model="searchForm.examMonth"
                type="month"
                placeholder="选择月"
                format="yyyy-MM"
                value-format="yyyy-MM">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="考试名称">
              <el-input v-model="searchForm.examName" placeholder="请输入考试名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="题目">
              <el-input v-model="searchForm.title" placeholder="请输入题目" clearable></el-input>
            </el-form-item>
            <el-button @click="_reset">重置</el-button>
            <el-button type="primary" style="margin-left:16px;" @click="_search">查询</el-button>
          </el-form>
        </div>
        <div class="export-btn">
          <el-button type="primary" @click="_examAnalyseExport()">导出</el-button>
        </div>
      </el-card>
      <!-- 头部筛选---结束 -->
      <!-- 表格区域---开始 -->
      <div class="table-box">
        <el-table
          :header-cell-style="cellHeaderStyleTwo"
          :cell-style="cellStyleTwo"
          :data="paperAnalyseData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          height="570">
          <el-table-column type="selection" :reserve-selection='true' width="55"></el-table-column>
          <el-table-column
            v-for="(item, index) in tableColumns"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            :show-overflow-tooltip="item.overflow"
          >
            <template slot-scope="scope">
              <span v-if="item.prop=='startTime'" style="white-space: pre-wrap;">
                {{ scope.row.startTime?`${scope.row.startTime} 至 ${scope.row.endTime}`:'—' }}
              </span>
              <span v-else-if="item.prop=='accuracyRate'">{{scope.row.accuracyRate!=undefined&&scope.row.accuracyRate!=null&&scope.row.accuracyRate!=''?`${scope.row.accuracyRate}%`:'—'}}</span>
              <span v-else>
                {{ scope.row[item.prop]!==undefined&&scope.row[item.prop]!==null&&scope.row[item.prop]!==''?scope.row[item.prop]:'—' }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页---start -->
        <el-pagination
          class="fx-row-end pt16"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[5, 10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="analyseTotal">
        </el-pagination>
        <!-- 分页---end -->
      </div>
      <!-- 表格区域---结束 -->
  </div>
</template>

<script>
import { getExamTopicAnalyseApi } from '@/api/examApi'
import tableFirstCol from '@/mixins/tableFirstCol'
import { getCookie } from '@/utils/util'
import { genSignParams, randomString } from '@/utils/sign'
export default {
  components: {},
  mixins: [tableFirstCol],
  data () {
    return {
      searchForm: {
        examMonth: '', // 月份
        examName: '', // 考试名称
        title: '', // 题目
        page: 1,
        pageSize: 10
      },
      analyseTotal: 0, // 考试分析总数
      // 表格数据
      paperAnalyseData: [],
      // 表格字段
      tableColumns: [
        { prop: 'title', label: '题目', width: '400px', overflow: true },
        { prop: 'questionTypeValue', label: '题型', width: '200px', overflow: true },
        { prop: 'accuracyRate', label: '正确率', width: '150px', overflow: true },
        { prop: 'errorNum', label: '出错人数', width: '150px', overflow: true },
        { prop: 'errorTester', label: '出错人' },
        { prop: 'examName', label: '考试名称', width: '300px', overflow: true },
        { prop: 'startTime', label: '考试时间', width: '300px', overflow: true }
      ],
      multipleSelection: [],
      selectQuestionIds: []
    }
  },
  created () {
    this._getExamAnalyseList()
  },
  mounted () {
  },

  methods: {
    // 考试分析列表
    _getExamAnalyseList () {
      getExamTopicAnalyseApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.paperAnalyseData = JSON.parse(JSON.stringify(res.object.results))
          this.analyseTotal = res.object.total
        }
      })
    },
    // 考生信息-分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._getExamAnalyseList()
    },
    // 考生信息-分页
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getExamAnalyseList()
    },
    // 重置
    _reset () {
      this.searchForm = {
        examMonth: '', // 月份
        examName: '', // 考试名称
        title: '', // 题目
        page: 1,
        pageSize: 10
      }
      this._getExamAnalyseList()
    },
    // 查询
    _search () {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this._getExamAnalyseList()
    },
    // 考试分析导出
    _examAnalyseExport () {
      // const timestamp = new Date().getTime()
      const tempParams = { ...this.searchForm, page: undefined, pageSize: undefined, questionIds: this.selectQuestionIds }
      const timestamp = new Date().getTime()
      const appId = '12345678'
      const nonceStr = randomString(8)
      const sign = genSignParams(appId, {
        body: JSON.stringify(tempParams),
        url: '/train/examInfo/examAnalyzeExport',
        timestamp: timestamp,
        nonceStr: nonceStr
      })
      // const tempParams = { ...this.searchForm, page: undefined, pageSize: undefined, questionIds: this.selectQuestionIds }
      this.$axios.post(process.env.VUE_APP_BASE_API + 'train/examInfo/examAnalyzeExport?' + 'timestamp=' + timestamp + '&nonceStr=' + nonceStr + '&sign=' + sign + '&appId=' + appId, tempParams, {
        headers: {
          token: getCookie('token'),
          'Content-Type': 'application/json;charset=UTF-8'
        },
        responseType: 'blob'

      }).then(res => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/vnd.ms-excel' })
        )
        const nameData = '试卷分析导出'
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.setAttribute('download', nameData)
        document.body.appendChild(a)
        a.click() // 执行下载
        window.URL.revokeObjectURL(a.href)
        document.body.removeChild(a)
      })
    },
    handleSelectionChange (val) {
      console.log(val, 'kkkk')
      this.multipleSelection = val
      this.selectQuestionIds = this.multipleSelection.map((item) => {
        return item.questionId
      })
    },
    getRowKey (row) {
      return row.questionId
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
.card-pd{
  /deep/.el-card__header{
    padding: 16px 24px;
  }
  /deep/ .el-card__body{
    padding: 16px 24px;
  }
}
  .form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 16px;
    margin-right: 32px;
  }
  /deep/.el-form-item__label{
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.90);
  }
}
.export-btn{
  /deep/.el-button{
    margin-left: 0px !important;
  }
}
// /deep/.el-button{
//   margin-left: 16px !important;
//   font-size: 14px;
//   height: 32px;
// }
/deep/.el-button--default{
  color:  rgba(0, 0, 0, 0.90);
}
.table-box{
  background-color: #fff;
  padding-bottom: 16px;
}
</style>
